export function pastMidnight() {
	var midnight = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
	/*
	 * We add 3600 * 6 to correct BR timezone (which is actually GMT-03). We
	 * add 6 to also compensate daylight some saving special timezone, so we
	 * are always sure the returned value is something between 2 and 4 hours
	 * AM.
	 */
	return (midnight.getTime() / 1000) + (3600 * 6);
}

export function timestampToBrDate(timestamp) {
	const date = new Date(timestamp * 1000);
	return date.toLocaleDateString('pt-br');
}

export function brDateToTimestamp(brdate) {
	const [d, m, y] = brdate.split('/').map(String);
	const date = new Date(y, m - 1, d);
	return Math.floor(date.getTime() / 1000);
}

export function brToday(plus) {
	var timestamp = new Date().getTime() / 1000;
	if (plus)
		timestamp += plus;
	return timestampToBrDate(timestamp);
}
