import React from 'react';
import Container from "react-bootstrap/Container";
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NavBar() {
	return (
	 <div>
	 <Container className='g-0'>
	  <Navbar bg='dark' variant='dark'>
   <Navbar.Brand href="#">Sistema de Acompanhamento de Pedidos</Navbar.Brand>
            <Nav className="ms-auto">
              <Nav.Link id='clientes' as={Link} to="/customer">Clientes</Nav.Link>
              <Nav.Link id='servicesexpired' as={Link} to="/expired2">Serviços Expirados</Nav.Link>
            </Nav>
	  </Navbar>
	 </Container>
	 </div>
	)
}

export default NavBar;
