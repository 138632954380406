import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Api from "./Api";
import { AuthContext } from './AuthContext';
import XTable from "./XTable";
import { pastMidnight, timestampToBrDate } from "./Utils";

const Expired2 = () => {
	const navigate = useNavigate();

  const [divs, setDivs] = useState([]);

	const {auth, setAuth} = useContext(AuthContext);

	const API_URL = process.env.REACT_APP_BASE_API_URL + '/api/v1/servicesexpired';

  const [popup, setPopup] = useState({ show: false, targetId: null });

  const showPopup = (id, customer_id = -1, fetchData) => {
    setPopup({ show: true, targetId: id, customer_id: customer_id, fetchData: fetchData });
  };

  async function updateServiceDeadline(offsetDays, id, fetchData) {
  	const get_url = process.env.REACT_APP_BASE_API_URL + '/api/v1/services/' + id;
	const upsert_url = process.env.REACT_APP_BASE_API_URL + '/api/v1/services';
  	Api({auth: auth}).fetchData(get_url)
	.then(result => result )
	.then(item => {
		item.call_again = pastMidnight() + offsetDays * 24 * 3600;
		return Api({auth: auth, API_URL: upsert_url}).upsertItem(item)
		.then(() => {
			if (fetchData)
				fetchData();
		});
		/*
		 * TODO: using navigate here is a *very ugly* workaround. We
		 * want to rerender the current page, forcing XTable call
		 * fetchData() again. We don't know how to do that. Using
		 * navigate() to the same route (/expired2) also doens't work,
		 * so we just change the page!
		 */
	});
  }

  const handleOptionClick = (offsetDays, id, fetchData) => {
    updateServiceDeadline(offsetDays, id, fetchData).then();
    setPopup({ show: false, targetId: null, fetchData: null }); // Fechar o popup
  };

  const closePopup = () => {
    setPopup({ show: false, targetId: null, fetchData: null });
  };

  /*
  	Botão finalizar -> entrar em contato novamente depois de 12 meses
  	Adicionar observação o finalizar
  */

  return (
  	<>
  	<XTable
  		renderButtonNew={false}
  		endpoint="servicesexpired"
  		fields={{
  			name: {title: 'Nome'},
  			phone: {title: 'Telefone'},
			type: {title: 'Produto' },
			date: {
				title: 'Data',
				convert: timestampToBrDate,
			},
			id: { from: 'service_id' },
			customer_id: {hidden: true}, /* TODO: workaround to pass customer_id */
  		}}
  		buttons={(fetchData) => (item) =>
  			<div>
			    <button className='wa' onClick={() => {
				window.open('https://wa.me/55' + item.phone.replace(/\D/g, ''), '_blank')
			    }}>
			      WA
			    </button>
			   <button className='blue' onClick={() => showPopup(item.id, item.customer_id, fetchData)}>Ações</button>
			   <button className='black'>Finalizar</button>
			</div>
		}
  	/>

	{popup.show && (
	  <div className="popup">
	    <div className="popup-content">
	      <p>Escolha uma opção:</p>
	      <button className='blue' onClick={() => handleOptionClick(1, popup.targetId, popup.fetchData)}>
	        Adiar por um dia
	      </button>
	      <button className='blue' onClick={() => handleOptionClick(7, popup.targetId, popup.fetchData)}>
	        Adiar por uma semana
	      </button>
	      <button className='blue' onClick={() => handleOptionClick(14, popup.targetId, popup.fetchData)}>
	        Adiar por 14 dias
	      </button>
	      <button className='black' onClick={() => navigate('/customers/' + popup.customer_id + '/services/list')}>
	        Ir para registro do cliente
	      </button>
	      <button className='black' onClick={closePopup}>Cancelar</button>
	    </div>
	  </div>
	)}
	</>
  )
};

export default Expired2;
