import React, { useState } from 'react';

function Edit({id, value, onChange, validator, validatorMessage}) {
	const [myValue, setMyValue] = useState(value);

	const errorBox =
		<div style={{backgroundColor: 'red'}}>
			{validatorMessage}
		</div>

	return (
		<>
		<input
			className="form-control"
			id={id}
			type="text"
			value={myValue}
			onChange={(e) => {
				setMyValue(e.target.value);
				onChange(id, e.target.value);
			}}
		/>
		{validator(myValue) ? <div></div> : errorBox}
		</>
	);
}

export default Edit;
