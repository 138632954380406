import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AuthContext } from './AuthContext';

import Api from "./Api";
import Edit from './widgets/Edit';
import ComboBox from './widgets/ComboBox';

export default function EntryEditor({fields, endpoint}) {
	const { state } = useLocation();

	let item = {};
	if (state.item)
		item = state.item;
	else
		/* For new items, just define a new object with empty strings */
		for (const key of Object.keys(fields))
			if (fields[key].initial)
				item[key] = fields[key].initial();
			else
				item[key] = "";

	const [myItem, setMyItem] = useState(item);
	const {auth, setAuth} = useContext(AuthContext);

	const navigate = useNavigate();

	let myEndpoint = endpoint;

	function handleSubmit(e) {
		e.preventDefault();
		/*
		 * Don't submit if one of the validators fail. Validators
		 * messages are shown in the Edit widget.
		 */
		for (let key in fields) {
			if (fields[key].validator(myItem[key]) === false)
				return;
			if (fields[key].convertBack)
				myItem[key] = fields[key].convertBack(myItem[key]);
		}

		if (state.parentField)
			myItem[state.parentField] = state.parentId;

		const API_URL = process.env.REACT_APP_BASE_API_URL + '/api/v1/' + myEndpoint;
		Api({auth: auth, API_URL: API_URL}).upsertItem(myItem)
			.then(() => navigate(-1));
	}

	function handleChange(key, value) {
		setMyItem({...myItem, [key]: value });
	}

	return (
		<Row>
		<Col>
		<form onSubmit={handleSubmit}>
		{Object.keys(myItem).filter(key => key !== "id").map(key => {
			var value = myItem[key];
			if (fields[key].convert)
				value = fields[key].convert(value);
			if (fields[key].type == 'ComboBox')
				return (
					<>
					<label>{fields[key].title}</label>
					<ComboBox
						key={key}
						id={key}
						value={myItem[key]}
						options={fields[key].options}
					 	onChange={handleChange}
					/>
					</>
				)
			return (
			 <>
			 <label>{fields[key].title}</label>
			 <Edit
			 	key={key}
			 	id={key}
			 	value={myItem[key]}
			 	validator={fields[key].validator}
			 	validatorMessage={fields[key].validatorMessage}
			 	onChange={handleChange} />
			 </>
			);
		})}
		<input className="form-control" type="hidden" id={myItem.id}/>
		<button className="btn btn-secondary" type="submit">Save</button>
		<button className="btn btn-secondary" type="button" onClick={() => 
			navigate(-1)
		}>Cancelar</button>
		</form>
		</Col>
		</Row>
	);

}
