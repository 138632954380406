import React, { useState, useContext, useEffect } from 'react';

import Api from "./Api";
import { AuthContext } from './AuthContext';

function CustomerData({id}) {
	const [data, setData] = useState([]);
	const {auth, setAuth} = useContext(AuthContext);
	const [loading, setLoading] = useState(true);

	const API_URL = process.env.REACT_APP_BASE_API_URL + '/api/v1/customers/' + id;

	function fetchData() {
		setLoading(true);
		Api({auth: auth}).fetchData(API_URL).then((result) => {
			setData(result);
			setLoading(false);
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (loading)
		return "Carregando...";

	return (
		<div>
		<h2>Informações do cliente:</h2>
		<table>
		<tr><td><strong>Nome:</strong></td><td>{data.name}</td></tr>
		<tr><td><strong>Endereço:</strong></td><td>{data.address}</td></tr>
		<tr><td><strong>Número:</strong></td><td>{data.streetnumber}</td></tr>
		<tr><td><strong>Complemento:</strong></td><td>{data.complement}</td></tr>
		<tr><td><strong>Cidade:</strong></td><td>{data.city}</td></tr>
		<tr><td><strong>CEP:</strong></td><td>{data.cep}</td></tr>
		<tr><td><strong>Telefone:</strong></td><td>{data.phone}</td></tr>
		<tr><td><strong>CPF:</strong></td><td>{data.cpf}</td></tr>
		</table>
		</div>
	);
}

export default CustomerData;

