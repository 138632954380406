// src/AuthContext.js
import React, { createContext, useState } from 'react';

const AuthContext = createContext();

function AuthProvider ({ children }) {
  const [auth, setAuth] = useState({ username: '', password: '' });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
