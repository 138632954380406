import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../AuthContext';

import Api from '../Api';

export default function Root() {
	const { auth, setAuth } = useContext(AuthContext);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(false);
	const navigate = useNavigate();

	function handleSubmit (e) {
		e.preventDefault();
		setAuth({ username, password });
		Api({
		 /*
		  * We cannot use the auth object set above by setAuth() right
		  * now, because the auth object is actually only set after
		  * Root() function finishes, so we need to use an object with
		  * username and password variables.
		  */
		 auth: {username: username, password: password},
		 API_URL: process.env.REACT_APP_BASE_API_URL
		}).login().then(x => {
			if (x)
				navigate('/expired2');
			else
				setError(true);
		});
	}

	return (<div>
		<div className="popup">
		<div className="popup-content">
		<form onSubmit={handleSubmit}>
			<span>Login:</span>
			<span><input type="text" id="username" placeholder="usuário" value={username}
			  className="form-control"
			  onChange={(e) => setUsername(e.target.value)}/></span>
			<span><input type="password" id="password" placeholder="senha" value={password}
			  className="form-control"
			  onChange={(e) => setPassword(e.target.value)}/></span>
			{error ? <><br/><span style={{color: "red"}}>Login inválido</span><br/></> : <></>}
			<button id="submit" className='black' type="submit">Entrar</button>
		</form>
		</div>
		</div>
	</div>);
}
