export function nonEmptyValidator (input) {
	return input.trim() !== "";
}

export function brDateValidator(input) {
	const regex = /^([0-2][0-9]|(3)[0-1])\/(0[1-9]|1[0-2])\/(\d{4})$/;
	if (!regex.test(input)) {
		return false;
	}
	const [d, m, y] = input.split('/').map(Number);

	/* Check the date again */
	const date = new Date(y, m - 1, d);
	return date.getDate() === d
	 && date.getMonth() === m - 1
	 && date.getFullYear() === y;
}
