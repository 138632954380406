import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import CustomerData from "./CustomerData";
import XTable from "./XTable";

import { brDateValidator } from './Validators';
import { brToday, brDateToTimestamp, timestampToBrDate } from "./Utils";


export const fields = {
	type: {
		title: 'Tipo',
		validator: (x) => true,
		initial: () => 'Aquecedor',
		type: 'ComboBox',
		options: ['Aquecedor', 'Filtro']
	},
	brand: {
		title: 'Marca',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
	model: {
		title: 'Modelo',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
	date: {
		title: 'Data',
		validator: brDateValidator,
		validatorMessage: 'A data deve estar no formato "DD/MM/YYYY"',
		convert: timestampToBrDate,
		convertBack: brDateToTimestamp,
		initial: brToday,
	},
	finished: {
		title: 'Finalizado',
		validator: (x) => true,
		initial: () => 0,
		type: 'ComboBox',
		options: ['Não finalizado', 'Finalizado'],
		convert: (x) => {
			if (x === 0)
				return 'Não finalizado';
			return 'Finalizado';
		},
		convertBack: (x) => {
			if (x === 'Não finalizado')
				return 0;
			return 1;
		},
	},
	call_again: {
		title: 'Ligar novamente',
		validator: () => true,
		validatorMessage: "TODO: error message",
		convert: timestampToBrDate,
		convertBack: brDateToTimestamp,
		initial: () => brToday(3600 * 24 * 182),
	},
	obs: {
		title: 'OBS',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
};

function Service() {
	const params = useParams();
	const endpoint = 'customers/' + params.customerId + '/services';

	return (
		<>
		<CustomerData id={params.customerId}/>
		<br/>
		<br/>
		<br/>
		<XTable
			editRoute='/services/edit'
			endpoint={endpoint}
			fields={fields}
			parentId={params.customerId}
			
		/>
		</>
	);
}

export default Service;
